<template>
    <b-card
        no-body
        class="card-payment"
    >
        <b-card-header>
            <b-card-title>Filtro</b-card-title>
        </b-card-header>
        <b-card-body>
            <!-- form -->
            <b-form>
                <b-row>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Numero de serie"
                            label-for="serial_number"
                            class="mb-2"
                        >
                            <b-form-input
                                id="serial_number"
                                v-model="search.serial_number"
                                type="text"
                                autocomplete="off"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Ubicació"
                            label-for="location"
                            class="mb-2"
                        >
                            <b-form-input
                                id="location"
                                v-model="search.location"
                                type="text"
                                autocomplete="off"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Descripción"
                            label-for="description"
                            class="mb-2"
                        >
                            <b-form-input
                                id="description"
                                v-model="search.description"
                                type="text"
                                autocomplete="off"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Tag"
                            label-for="tag"
                            class="mb-2"
                        >
                            <v-select
                                v-model="search.tag_id" label="code"
                                :reduce="(option) => option.id"
                                :options="tags">
                                <template #no-options="{}">
                                    No se han podido cargar los tags
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-button
                            block
                            variant="primary"
                            class="mt-2"
                            @click="searchEquipment"
                        >
                            Buscar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <!--/ form -->
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm } from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"


const {mapFields} = createHelpers({
    getterType: 'equipment/getField',
    mutationType: 'equipment/updateField',
})

export default {
    name: "SearchView",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect
    },
    props: {
        clickSearch: {
            type: Function,
        }
    },
    computed: {
        currentWorkCenter() {
            return this.$store.state.workCenter
        },
        ...mapFields({
            search: 'search',
        }),
    },
    data() {
        return {
            clients: [],
            tags: [],
        }
    },
    watch: {
        currentWorkCenter() {
            this.getClients()
        }
    },
    created() {
        this.getClients()
        this.getTags()
    },
    methods: {
        getClients() {
            this.$store.dispatch('client/listByEquipmentsList')
                .then(response => {
                    this.clients = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getTags() {
            this.$store.dispatch('tag/listClientTags', this.$store.state.AppActiveUser.client_id)
                .then(response => {
                    this.tags = response  
                })
                .catch(error => {
                    console.log(error)
                })
        },
        searchEquipment: function (){
            this.clickSearch()
        }
    }
}
</script>

<style scoped>

</style>