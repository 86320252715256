<template>
    <b-row>
        <b-col cols="12" class="p-0">
            <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
                <b-row class="m-0 w-100">
                    <b-col cols="12">
                        <search-view :click-search="getEquipments" />
                    </b-col>
                    <b-col v-for="equipment in equipments" :key="equipment.id" cols="12" sm="6" lg="4">
                        <b-link :to="{ name: 'sheet_equipment', params: { id: equipment.id } }">
                            <b-card no-body>
                                <div class="item-img text-center">
                                    <b-img alt="asdf" fluid class="card-img-top"
                                        :src="baseUrl + (equipment.equipment_photo ? 'storage/' + equipment.equipment_photo : 'images/placeholder.webp')"
                                        style="height: 200px; object-fit: cover; object-position: center;" />
                                </div>

                                <!-- Product Details -->
                                <b-card-body>
                                    <table>
                                        <tr>
                                            <td class="text-muted pr-1">Nº Serie:</td>
                                            <td class="text-dark">{{ equipment.serial_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted pr-1">Cliente:</td>
                                            <td class="text-dark">{{ equipment.client.name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted pr-1">Descipción:</td>
                                            <td class="text-dark"
                                                style="width: 100%; max-width: 1px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                                                {{ equipment.description }}
                                            </td>
                                        </tr>
                                    </table>
                                </b-card-body>

                                <!-- Product Actions -->
                                <div class="item-options text-center">
                                    <div class="bg-primary text-white py-1 w-100 rounded-bottom">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        <span>Ver equipo</span>
                                    </div>
                                </div>
                            </b-card>
                        </b-link>
                    </b-col>
                </b-row>
            </b-overlay>

            <!-- Pagination -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">
                            Mostrando {{ pageLength }} de {{ totalRecords }} Equipos
                        </span>
                    </b-col>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination :value="1" :total-rows="totalRecords" :per-page="pageLength" first-number last-number
                            align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                            @input="changePage">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import { BOverlay, BRow, BCol, BCard, BPagination, BLink, BImg, BCardBody, BCardText } from 'bootstrap-vue'
import { mapState } from "vuex"
import { baseUrl } from '@/constants/app'
import SearchView from "@/views/equipments/list/components/searchView"
import vSelect from "vue-select"
export default {
    name: "ListView",
    components: {
        BOverlay,
        BRow,
        BCol,
        BCard,
        BPagination,
        BLink,
        BImg,
        BCardBody,
        BCardText,
        SearchView,
        vSelect,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            baseUrl,
            columns: [
                { key: 'serial_number', label: 'Numero de serie', sortable: false },
                { key: 'description', label: 'Descripción', sortable: false },
                { key: 'client', label: 'Cliente', sortable: false },
                { key: 'actions', label: 'Opciones', sortable: false },
            ],
            searchTerm: '',
            showLoading: false,
        }
    },
    computed: {
        ...mapState('equipment', ['equipments', 'search']),
    },
    created() {
        this.getEquipments()
    },
    beforeDestroy() {
        this.$store.commit('SET_PAGE_TITLE', null)
    },
    methods: {
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        searchEquipments() {
            this.getEquipments()
        },
        getEquipments() {
            this.showLoading = true

            this.$store.dispatch('equipment/searchEquipments', {
                per_page: this.pageLength,
                page: this.page,
                filter: this.search,
            })
                .then(response => {
                    this.showLoading = false
                    this.totalRecords = response.total_records
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        changePage(page) {
            this.page = page
            this.getEquipments()
        },
        changeLength(length) {
            this.length = length
            this.getEquipments()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>